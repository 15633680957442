<template>
    <Assignment
      :assignmentId="$route.params.assignmentId"
      :participantId="$route.params.participantId"/>
</template>

<script>
import Assignment from '@/components/Assignment.vue'

export default {
  name: 'assignment',
  components: {
    Assignment
  }
}
</script>
