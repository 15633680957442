
import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class Assignment extends Vue {
  @Prop({ required: true }) private assignmentId!: string;
  @Prop({ required: true }) private participantId!: string;
  private loading = true;
  private revealed = false;
  private assignment = null;

  mounted () {
    axios.get(`/api/${this.assignmentId}/${this.participantId}.json`)
      .then(response => { this.assignment = response.data })
      .catch(() => { this.$router.replace('/not-found') })
      .finally(() => { this.loading = false })
  }
}
